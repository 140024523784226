import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/installation/:proprio',
      name: 'installation',
      component: () => import(/* webpackChunkName: "installation" */ './views/Installation.vue'),
      props: (route) => ({ proprio: route.params.proprio })
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/contactus',
      name: 'contactus',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "contactus" */ './views/ContactUs.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/login',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/account',
      name: 'account',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "account" */ './views/Account.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/share',
      name: 'share',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "share" */ './views/ShareInstall.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/createAccount',
      name: 'createAccount',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "createAccount" */ './views/CreateAccount.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/installations',
      name: 'installations',
      component: () => import(/* webpackChunkName: "installations" */ './views/Installations.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/presentation/:proprio',
      name: 'presentation',
      component: () => import(/* webpackChunkName: "presentation" */ './views/Presentation.vue'),
      props: (route) => ({ proprio: route.params.proprio })
    },
    {
      path: '/commentaires/:proprio',
      name: 'commentaires',
      component: () => import(/* webpackChunkName: "commentaires" */ './views/Commentaires.vue'),
      props: (route) => ({ proprio: route.params.proprio })
    },
    {
      path: '/members',
      name: 'members',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "members" */ './views/Members.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/admin',
      name: 'adminPage',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "adminPage" */ './views/AdminPage.vue'),
      //props: (route) => ({ proprio: route.query.proprio })
    },
    {
      path: '/Installation-Home-Cinema-:code.html',
      name: 'install_old_link',
      component: () => import(/* webpackChunkName: "install_old_link" */ './views/ReRoute.vue'),
      props: (route) => ({ code: route.params.code })
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    }
  ]
})
