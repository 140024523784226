<template>
  <div class="lastComm">
    <h3>Les derniers commentaires<span class="debug">{{ scrollPosition }}</span></h3>
    <div class="listeComm" ref="listeComm">
      <div v-for="comments in json" v-bind:key=comments._ID> 
        <div class="d-inline-block imgDiv">       
          <router-link :to="{path: 'presentation/' + comments.proprioC.replace(/ /gi,'___') }">            
            <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + comments.vignette" :alt="'Installation de ' + comments.proprioC" class="shadow"/>        
          </router-link>
        </div>
        <div class="align-top d-inline-block commentDiv">
          <h4 v-if="comments.proprioC == comments.auteur">A propos de son installation, {{ comments.auteur }} a écrit&nbsp;:</h4>
          <h4 v-else>A propos de l'installation de {{comments.proprioC}}, {{ comments.auteur }} a écrit&nbsp;:</h4>
          <p>{{comments.message}}</p>
        </div>
      </div>
    </div>       
  </div>
</template>

<script>
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
export default { 
  name: 'LastComm',
  components: {    
    VLazyImage
  },  
  data() {         
    return {
      json: null,
      scrollPosition: null     
    }    
  },   
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    info: function() {
      alert(event.target.parentNode.clientHeight)
    },
    cleanProprio: function(proprio) {
      return this.proprio.replace(/___/gi,' ')
    }    
  },
  mounted() {      
    window.addEventListener('scroll', this.updateScroll);
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/liste/derniers/10')
    .then(response => (this.json = response.data.comments))
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.updateScroll)
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  font-size:1.05em;
  font-weight:bold;
  margin-left: -10px;
  text-align: center;
}

h4 {
  font-size:0.9rem;
  margin-bottom:2px;
  font-weight: bold;
}

p {
  margin-bottom:2px;
  text-align:justify;
}

.lastComm {
  text-align:left;  
  padding-bottom:10px;
}

img {
  width:60px;  
  /* transform: perspective(300px) rotateY(45deg); */
}

.imgDiv {  
  width:60px;  
}

.commentDiv {
  box-sizing: border-box;
  width:calc(100% - 60px);
  padding:2px;
  padding-left:7px;
}

.comments {
  margin-bottom:2px;
}

.showComm {
  display:block;
}

.hideComm {
  display:none;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
  transform: perspective(300px) rotateY(45deg);
}

</style>
