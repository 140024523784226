<template>
  <div id="menuToggle">
    <input type="checkbox" />    
    <span></span>
    <span></span>
    <span></span>
    <ul id="menu">
      <li v-if="$route.name === 'installation'"><a href="#" @click="goPresentation"><SvgPathIcon name="arrow-10" />Retour</a></li>
      <li><router-link to="/"><SvgPathIcon name="home-1" />Accueil</router-link></li>
      <li v-if="isShown"><router-link to="/account"><SvgPathIcon name="connexion-2" />Mon compte</router-link></li>
      <li v-else>
        <router-link to="/login"><SvgPathIcon name="connexion-2" />Connexion</router-link>      
      </li>
      <li v-if="isOwner">
        <router-link :to="{path: '/presentation/' + proprio }"><SvgPathIcon name="speaker-8" />Mon Installation</router-link>
      </li>
      <li v-if="isShown">
        <router-link to="/share"><SvgPathIcon name="pencil-9" />Partager mon HC</router-link>
      </li>
      <li v-if="isAdmin">
        <router-link to="/admin"><SvgPathIcon name="connexion-2" />Administration</router-link>      
      </li>
      <li v-if="!isShown">
        <router-link to="/createAccount"><SvgPathIcon name="pencil-9" />Créer un compte</router-link>
      </li>
      <li><router-link to="/installations"><SvgPathIcon name="install-3" />Les Installations</router-link></li>
      <li><router-link to="/members"><SvgPathIcon name="members-6" />Les membres</router-link></li>
      <li><router-link to="/about"><SvgPathIcon name="about-4" />A propos</router-link></li>
      <li><router-link to="/contactus"><SvgPathIcon name="contact-5" />Nous contacter</router-link></li>
    </ul>
  </div>
</template>

<script>
import SvgPathIcon from "./SvgPathIcon.vue"
//import SvgImgIcons from "./SvgImgIcons.vue";
//import SvgInlineIcons from "./SvgInlineIcons.vue";
export default {  
  components: {
    SvgPathIcon
  },
  props: {
    authenticated: Boolean
  },
  data() {
    return {
      iconNames: [
        "home-1",
        "connexion-2",
        "install-3",
        "about-4",
        "contact-5",
        "members-6",
        "speaker-8",
        "pencil-9",
        "arrow-10"
      ]      
    }
  },
  computed: {
    isShown: function() {
      if(this.authenticated === true) {
        return true
      }
      else if(localStorage.login) {
        return true
      }
      else {
        return false
      }
    },
    isAdmin: function() {
      if(localStorage.admin) {
        return true
      } else {
        return false
      }
    },
    isOwner: function() {
      if(localStorage.installOwner) {
        return true
      } else {
        return false
      }
    },
    proprio: function() {
      var endPath = null
      if(localStorage.login && localStorage.installOwner) {
        endPath = localStorage.login.replace(/ /gi,'___')
      } 
      return endPath
    }
  },
  methods: {
    goPresentation: function() {
      let proprio = this.$route.path.split('/')
      //alert(proprio[2])
      //this.$router.go(-1)
      this.$router.push('/presentation/' + proprio[2]) 
    }    
  }
}

</script>

<style scoped>

a
{
  text-decoration: none;
  color: #2c3e50;  
  transition: color 0.3s ease;
}

a:hover
{
  color: #007bff;
  text-decoration:none;  
}

#menuToggle
{
  display: block;
  position: relative;
  top: 10px;
  left: 10px;  
  z-index: 110;  
  -webkit-user-select: none;
  user-select: none;
  text-align:left;
  min-width:350px;
}

#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 120; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #555555;
  border-radius: 3px;
  
  z-index: 110;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #2c3e50;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 280px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 100px;
  padding-bottom:100px;
  border-radius: 0 0 10px 0;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  box-shadow: -1px 2px 10px 1px rgba(44, 62, 80, 0.7);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}

svg,
img {
  margin-right:4px;
  vertical-align: middle;
  opacity: 0.45;
}

</style>