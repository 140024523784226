<template>
    <div class="quickNews shadow whitebg">
        <p>Bonjour <span class="blue">{{ login }}</span>,</p>        
        <p v-if="1 === 0">Prochainement ici notification en cas de commentaire...</p>
        <div v-if="1 === 1">
            <p>Votre dernière séance <span class="blue">Home-Cinema</span> :</p>
            <p v-if="error===false" class="error blue">Pris en compte !</p>
            <p v-else-if="error===true" class="error red">Erreur !</p>
            <form autocomplete="off" v-on:submit.prevent="checkForm" method="post">
                <table>
                    <tr>
                        <td>
                            <label for="titrevideo">Titre</label>
                        </td>                                                
                    </tr>
                    <tr>
                        <td>
                            <AutocompleteInput type="movie" v-model="titrevideo" @textFound="setTitre($event)"/>                            
                        </td>                        
                    </tr>
                </table>               
                <table>
                    <tr>
                        <td>
                            <label for="supportvideo">Support</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <select name="supportvideo" v-model="supportvideo">
                                <option value="ld">LD</option>
                                <option value="dvd">DVD</option>
                                <option value="hddvd">HD-DVD</option>
                                <option value="bd">BD</option>
                                <option value="bd3d">BD 3D</option>
                                <option value="bd4k" selected>BD 4K</option>
                                <option value="vod" selected>VOD</option>
                            </select>
                        </td>
                    </tr>
                </table>               
                <table>
                    <tr>                        
                        <td>
                            <label for="note">Note</label>
                        </td>
                    </tr>
                    <tr>                        
                        <td>
                            <select name="note" v-model="note">
                                <option value="">--</option>
                                <option value="5">5</option>
                                <option value="4">4</option>
                                <option value="3">3</option>
                                <option value="2">2</option>
                                <option value="1">1</option>
                            </select>
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td>
                            <label for="topvideo">Top Vidéo</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="checkbox" name="topvideo" v-model="topvideo">
                        </td>
                    </tr>
                </table>                
                <table>
                    <tr>
                        <td>
                            <label for="topaudio">Top Audio</label>
                        </td>                
                    </tr>
                    <tr>                        
                        <td>
                            <input type="checkbox" name="topaudio" v-model="topaudio">
                        </td>                
                    </tr>
                </table>
                <table>
                    <tr>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="button shadow" type="submit" :disabled="isDisabled" value="Valider">
                        </td>
                    </tr>
                </table>                       
            </form>
        </div>   
        <p class="text-center blue" v-if="1 === 1"><br>De nouvelles fonctionnalités arrivent ! Revenez nous voir fréquemment.</p>     
    </div>
</template>

<script>
import AutocompleteInput from '@/components/AutocompleteInput.vue'
export default {
    name: 'quickNews',
    components: {
        AutocompleteInput
    },
    props: {
        login: {
            type: String,
            default: null
        }    
    },
    data() {         
        return {   
            isDisabled: null, 
            error: null,
            titrevideo: null,
            supportvideo: null,
            titreid: null,
            note: null,
            topvideo: null,
            topaudio: null         
    }
  },
  methods: {
      checkForm: function(e) {
          this.error = null
          if(this.titrevideo !== null && this.titrevideo.trim() !== "" && this.supportvideo !== null ) {
            this.submitForm()
          }
          else {
              this.error = true
          }          
          e.preventDefault()
      },
      submitForm: function() {
        var apiUrl = "/backend/get/form/lastmovie"
        fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({titre: this.titrevideo,id: this.titreid, support: this.supportvideo, note: this.note, topvideo: this.topvideo, topaudio: this.topaudio})
        })
        .then(res => res.json())
        .then(res => {
          if(res.retour != "ok") {
            this.error = true                        
          }
          else if(res.retour === "ok") {            
            this.error = false    
            this.isDisabled = true        
          }                       
        });
      },
      setTitre: function(e) {
          this.titrevideo = e.texte.trim()
          this.titreid = e.id.trim()
      }
  }
}
</script>

<style scoped>

.quickNews {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
    border-radius:5px;
}

form {
    text-align: center;
}

input {
    height:30px;
}

input[type="radio"], input[type="checkbox"]   {
    vertical-align: bottom;
}

select {
    height:30px;
}

table {
    display: inline-block;
    margin-right:3px;
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  height:30px;
  margin:0;
  padding-left:15px;
  padding-right:15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.button:disabled {
    color: #999;
}

.error {
    font-weight: bold;
    text-align: center;
}

p {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
</style>