<template>  
  <div id="app" class="w-100">
    <!-- <div id="nav">
      <router-link to="/"><img class="navLogo" src="./assets/home-icon.png" alt="Accueil"/></router-link> 
      <router-link to="/about"><img class="navLogo" src="./assets/contact-icon.png" alt="A propos"></router-link>
    </div> -->
    <router-view></router-view>
    <div class="debug">
      {{ txt }}
    </div>
  </div>
</template>

<script>
export default {
    data() {
      return {
        windowHeight: 0,
        txt: ''
    }
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },
  mounted() {    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight
      });
    })
  }
}
</script>

<style>

body,html {
  overflow: hidden;
}

html {
  overflow-y:auto;
}

body {
  margin:0px;  
  background:url('~@/assets/curve-industry-photography-vintage.jpg') no-repeat top center;    
  }

/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/barlow-condensed-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed Regular'), local('BarlowCondensed-Regular'),
       url('~@/assets/fonts/barlow-condensed-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~@/assets/fonts/barlow-condensed-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('~@/assets/fonts/barlow-condensed-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('~@/assets/fonts/barlow-condensed-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~@/assets/fonts/barlow-condensed-v4-latin-regular.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

#app {
  font-family: "Barlow Condensed", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;    
  max-height:100%;  
}

#nav {
    position: fixed;
    bottom: 10px;
    width: calc(100% - 100px);
    text-align: center;
    background-color: rgba(247, 247, 247, 0.8);
    box-shadow: -1px 2px 10px 1px rgba(44, 62, 80, 0.7);
    left:50%;
    margin-left: calc((100% - 100px) / -2);
    padding: 8px;
    border-radius: 5px;
    z-index:1000;    
}

nav {
  height:45px;
  box-shadow: -1px 2px 10px 1px rgba(44, 62, 80, 0.7);
  background:url('~@/assets/logo_ilhc_100px.png') no-repeat 65px 8px;
  background-color:#fff;
}

.shadow {  
  box-shadow: -1px 2px 10px 1px rgba(44, 62, 80, .7);
}

.debug {
  display:none;
}

.MinimizeIcon {
  height:20px;
  width:20px;
  display:inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAASFBMVEUAAAAAgv8AUqsAUakAadoAX8UAbd8AiP8Adu8AePMAYcoARpQATJ8AbtwAWbsAT6UATqQAYs4AVK8AZNEAY88Af/8Ag/8AevzDjZZqAAAAFXRSTlMAw2dlxRLNy7msqTEtzYlYVRYLxMSirHKqAAAAaElEQVQY083RSRKAIAxE0ZCoODJp4/1vKrKwSBUH8C/fLh2ifTGqZSfa1gxVXjea8yTCX8JTnsnCkyrAkoFoFJiCrJH/gSmOtZgavHDXcDbojqF2uIr9My2CRg/7TsftdFKm647cfccDtk8Itns6TmUAAAAASUVORK5CYII=) no-repeat;
}

.graybg {
  background: rgba(242, 242, 242, .4);
}

.whitebg {
  background: rgba(255, 255, 255, .5);
}

.blue {
    color: #007bff;
}

.red {
    color: darkred;
}

</style>
