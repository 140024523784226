<template>
  <div class="installations">
    <h3>Les dernières installations Home-Cinema</h3>
    <div class="content">
      <div class="d-inline-block" v-for="installations in json" v-bind:key=installations.ID>
        <div class="preview">
          <router-link :to="{path: 'presentation/' + installations.secureProprio }">
            <!-- <img :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + installations.vignette " :alt="'Installation de ' + installations.Proprio" class="shadow"/>             -->
            <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + installations.vignette" :alt="'Installation de ' + installations.Proprio" class="shadow"/>        
          </router-link>
        </div>
      </div>          
    </div>
    <div class="footer">
      <router-link to="/installations">Toutes les Installations...</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
export default { 
  name: 'ListInstall',
  components: {
    VLazyImage
  },  
  data() {         
    return {
      json: null
    }    
  },   
  mounted() {    
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/liste/40/desc')
    .then(response => {this.json = response.data.installations;
    })    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

img {
  border:1px solid #ddd;
  border-radius:8px;
}

.preview {  
  overflow:hidden;  
  height:100px;  
  margin:2px;
}

.installations {
  width:99%;
  margin:0 auto;
  margin-top:5px;
}


h3 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.content {
  height:216px;
  overflow: hidden;
}

a {
  font-size:1.05em;  
}

a:hover {
  text-decoration: none;
}

.footer {
  text-align: right;
  margin-bottom: 5px;
  padding-right: 50px;
  font-weight: bolder;
}

</style>
