<template>
  <div class="installHC clearfix">        
    <div id="subHeader">
      <p>
        <SvgPathIcon name="login-7" />
          <span v-if="login"><router-link to="/account">{{ this.login }}</router-link></span>
          <span v-else ><router-link to="/login">Bienvenue</router-link></span>        
      </p>
    </div>    
  </div>
</template>

<script>
import axios from 'axios'
import SvgPathIcon from "./SvgPathIcon.vue"
export default {
  components: {
    SvgPathIcon
  },
  name: 'InstallHC',
  props: {  
  },
  data() {
    return {
      json: null,
      login: null,
      iconNames: [
        "login-7"
      ]
    }
  },
  beforeCreate() {  
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/validateAccount').then(response => {this.json = response.data.account;
      let vm = this;      
      if(!this.json.error) {
        vm.login = this.json.login        
        localStorage.login = this.json.login
        if(this.json.admin == "true") {
          localStorage.admin = this.json.admin
        }
        if(this.json.installOwner == "true") {
          localStorage.installOwner = this.json.installOwner
        }
      } else {
        localStorage.removeItem('login')   
        localStorage.removeItem('installOwner')   
        localStorage.removeItem('admin')   
        this.$emit("notlogged")
        //this.$router.push('/') 
        //location.reload()     
      }
    })      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #2c3e50;
  transition: color 0.3s ease;
}

a:hover
{
  color: #007bff;
  text-decoration:none;  
}

p {  
  line-height:18px;
  font-size:1.1em;
  font-weight: bold;   
  padding-right:10px;
  text-align:right;
}

.installHC {
  padding-top:10px;
  position: relative;
  z-index: 200;
}

svg {
  margin-bottom: -4px;
  opacity: 0.5;
}
</style>