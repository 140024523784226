<template>
  <div> 
    <h3>Les dernières séances des membres</h3> 
    <div class="LastMovies shadow"> 
      <div class="board">
        <div class="text">
          <p v-for="movie in json" v-bind:key=movie._ID>
            {{ movie.login }} vient de regarder&nbsp;
            <span v-if="movie.link"><a :href="movie.link" target="_new">{{ movie.titre }}</a> en {{ getSupport(movie.support) }}</span>
            <span v-else>{{ movie.titre }} en {{ getSupport(movie.support) }}</span>
            <span v-if="movie.note !== null">&nbsp;{{ getNote(movie.note) }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
export default { 
  name: 'Star',  
  components: {
    VLazyImage    
  },  
  data() {         
    return {
      json: null,
      show: true   
    }    
  },  
  mounted() {  
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/lastmovies').then(response => (this.json = response.data.lastmovies))          
  },
  methods : {
    getSupport(idSupp) {
      if(idSupp == "hddvd") {
        idSupp = "hd-dvd"
      }
      else if(idSupp == "bd3d") {
        idSupp = "bd 3d"
      }
      else if(idSupp == "bd4k") {
        idSupp = "bd 4k"
      }
      return idSupp.toUpperCase();
    },
    getNote(value) {
      let i = 0
      let note = ''
      while( i <  value) {
        note = note + '⭐'
        i++
      }
      return note
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.LastMovies {
  min-height:100px;
  margin-top:5px;
  margin-bottom:5px;
  background-color:#111;
  color:#FFE81F;
  width:100%;  
  overflow: hidden;
  padding-top: 42.5%;
  position: relative;  
  border:1px solid #eee;
}

.board {
  position: absolute;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  transform: perspective(19rem) rotateX(38deg);
  transform-origin: 50% 100%;
  text-align: justify;  
  margin-left: -60%;
  font-weight: bold;
  overflow: hidden;
  font-size: 340%;
  height: 400%;
  width: 120%;
  bottom: 0;
  left: 50%;
}

@media screen and (max-width: 639px) {
    .board {
        font-size: 100%;
    }
}


@media screen and (min-width: 639px) and (max-width: 799px) {
    .board {
        font-size: 160%;
    }
}

@media screen and (min-width: 799px) and (max-width: 1023px) {
    .board {
        font-size: 220%;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1199px) {
    .board {
        font-size: 280%;
    }
}



.text {
  animation: scroll 50s linear 6s infinite;
  position: absolute;
  top: 100%;
}

.text:hover{
  -webkit-animation-play-state:paused;
  -moz-animation-play-state:paused;
  -o-animation-play-state:paused;
  animation-play-state:paused;
  cursor: pointer;
}

@keyframes scroll {
    0% {
        top: 100%;
    }
    100% {
        top: -170%;
    }
}

.board:after {    
  position: absolute;
  content: ' ';
  bottom: 60%;
  left: 0;
  right: 0;
  top: 0;
}

h3 {
  font-size:1.05em;
  font-weight:bold;
  margin-left: -10px;  
  margin-bottom:0;
  text-align: center;
}

a:hover {
  text-decoration: none;
}
</style>
