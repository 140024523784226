<template>
  <div class="Star">
    <h3>L'Installation Home-Cinema du moment<span class="debug"></span></h3>
    <transition name="fade">        
      <div class="wrapper">
        <div v-for="installation in json" v-bind:key=installation._ID class="item">
          <router-link :to="{path: 'presentation/' + installation.secureProprio }" :title="installation.proprio" class="polaroid">            
            <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/voir/' + installation.vignette + '/9' " :alt="'Installation de ' + installation.proprio" />
            <div class="caption">Home-Cinema de  {{ installation.proprio }}</div>
          </router-link>                      
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
export default { 
  name: 'Star',  
  components: {
    VLazyImage    
  },  
  data() {         
    return {
      json: null,
      show: true   
    }    
  },  
  mounted() {  
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/random').then(response => (this.json = response.data.installation))  
    setInterval(() => {        
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/random').then(response => (this.json = response.data.installation))  
    }, 20000);  
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.Star {
  min-height:200px;
}

h3 {
  font-size:1.05em;
  font-weight:bold;
  /* margin-left: -10px;  */
  margin-bottom:0;
  text-align: center;
}

* {
  box-sizing: border-box;
}


.wrapper {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}

.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
  display:block;  
}

.polaroid > img{
  max-width: 100%;
  width:100%;
  height: auto;
}

.caption {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.8em;
}
.item {
  /* width: 99%; */
  display: inline-block;
  margin-top: 0.5rem;
  filter: grayscale(100%);
}
.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:nth-of-type(4n+3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:hover {
  filter: none;
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
  transition: all 0.35s;
}

a:hover {
  text-decoration: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

</style>
