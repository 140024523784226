<template>
    <div v-if="size==='small'">
        <VueScriptComponent script='<script type="text/javascript" src="https://tags.clickintext.net/jVmvFlIqHqTgr" title="Classic (IAB)"></script>'/>
    </div>
    <div v-else-if="size==='medium'">
      <VueScriptComponent script='<script type="text/javascript" src="https://tags.clickintext.net/Uj2tMkak0FfWa" title="Classic (IAB)"></script>'/>
    </div>
    <div v-else-if="size==='large'">
      <VueScriptComponent script='<script type="text/javascript" src="https://tags.clickintext.net/tAM9SenY8yhPA" title="Classic (IAB)"></script>'/>
    </div>
    <div v-else>
      <VueScriptComponent script='<script type="text/javascript" src="https://tags.clickintext.net/jtlYZaDXO4Txl" title="Classic (IAB)"></script>'/>
    </div>
</template>

<script>
import VueScriptComponent from 'vue-script-component'

export default {  
  components: {
    VueScriptComponent
  },
  props: {
    size: {
      type: String,
      default : "large"    
    }
  },
}
</script>