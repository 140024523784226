<template>
  <div class="home">
    <vue-headful
            title="Install-HC - Bienvenue sur le site"
            description="Site consacré aux Installations Home-Cinema de particuliers"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC @notlogged="notlogged"/>
        </div> 
        <Menu :key="componentKey" />
      </nav>
    </div>
    <ListInstall/>    
    <Actu/>
    <Pub size="large"/>
    <!-- <div class="Content row  flex-column-reverse flex-md-row"> -->
    <div class="Content row">      
      <div  class="col-md">
        <QuickNews v-if="login !== null" :login="this.login"/>
        <Star/>
      </div>                
      <div  class="col-md">        
        <LastMovies/>
        <LastComm/>
      </div>                
      
    </div>    
    <Pub size="large"/>
    <div class="header">
      <h6><router-link to="/">Install-HC 2020.6</router-link></h6>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import InstallHC from '@/components/InstallHC.vue'
import ListInstall from '@/components/ListInstall.vue'
import LastComm from '@/components/LastComm.vue'
import Actu from '@/components/Actu.vue'
import Star from '@/components/Star.vue'
import Menu from '@/components/Menu.vue'
import QuickNews from '@/components/QuickNews.vue'
import LastMovies from '@/components/LastMovies'
export default {
  name: 'home',
  components: {
    InstallHC,
    ListInstall,
    LastComm,
    Actu,
    Star,
    Menu,
    QuickNews,
    LastMovies
  },
  data() {         
    return {      
      componentKey: 0,
      login: null
    }
  },
  methods:{
    forceRerender: function() {
      this.componentKey += 1
    },
    notlogged: function() {
      this.forceRerender()
    },
    getLogin: function() {      
      this.login = localStorage.getItem('login')          
    }
  },
  mounted() {
    this.getLogin()
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
  color: #2c3e50;  
  transition: color 0.3s ease;
}

a:hover {
  color: #007bff;
  text-decoration: none;
}

h1 {   
  line-height: 35px;
  font-size: 35px;
  margin:0px;
  text-align: left;
  padding-right: 10px;  
}

h6, .h6 {
    font-size: 0.8rem;
    margin-top: 10px;
    text-align: right;
    padding-right: 10px;
}

.Content {
  width:95%;
  height:100%;
  overflow-y: visible;
  margin:0 auto;
}


</style>