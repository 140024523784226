<template>
    <div class="d-inline-block">
        <!-- <input v-for="designation in materiel" v-bind:key=designation._id type="text" v-model="searchDesignation" class="designation" @input="onChange" :run="setValue('designation', designation.Texte)"/> -->
        <input :name="type" maxlength="210" @input="onChange" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" v-model="search" @keydown.delete="onBackspace" @keydown.backspace="onBackspace" :run="setValue(strValue)"/>
        <ul id="autocomplete-results shadow" v-show="isOpen" class="autocomplete-results">
            <li class="loading" v-if="isLoading">
                Chargement...
            </li>
            <li v-else v-for="(response,i) in results" :key="i" @click="setResult(response.Texte)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
                <!-- {{ result }} -->
                {{ response.Texte }}
            </li>            
        </ul>
    </div>
</template>

<script>
export default {    
    name: 'AutocompleteInput',
    components: {        
    },
    props: {
        strValue: String,
        type: String
    },
    data() {         
        return {
            json: null,
            results: null,
            search: null,
            isOpen: false,
            /* results: [], */
            isLoading: false,
            arrowCounter: 0,
            selectedId: null            
        }    
    },
    methods: {
        setValue: function(defaultValue) {
            if( this.search === null) {
                this.search = defaultValue
            }
        },
        onChange: function(e) {        
            this.$emit('input', this.search);              
            this.autocomplete(this.type,this.search)              
        },
        autocomplete: function(type, text) {
            if(text.length > 1 ) {
                var apiUrl = "/backend/get/form/autocomplete/" + type
                fetch(apiUrl,{
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({text: text})
                })
                .then(res => res.json())
                .then(res => {          
                    this.results = res.response                    
                    this.filterResults()                    
                    this.isOpen = true   
                })
            }
        },
        filterResults() {
            // first uncapitalize all the things            
            let vm = this
            this.results = this.results.filter(function(t) {                
                return t.Texte.toLowerCase().startsWith(vm.search.toLowerCase())            
            })                
                if(this.isEmpty(this.results) === true) {
                    this.isOpen = false
                    this.isLoading = false
                    this.arrowCounter = -1
                }
            //this.results = this.items.filter((item) => {
            //return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            //});
        },
        setResult(result) {
            this.search = result
            this.isOpen = false
            this.foundIdInJson(this.search)
            this.$emit('textFound', { id: this.selectedId, texte: this.search })             
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter -1;
            }
        },
        onEnter() {
            this.search = this.results[this.arrowCounter].Texte;
            this.isOpen = false
            this.arrowCounter = -1
        },
        onBackspace() {
            if(this.search !== null && this.search.length === 1) {
                this.isOpen = false
                this.arrowCounter = -1
            }            
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                return false;
            }
        return true;
        },
        foundIdInJson: function(string) {
            for (let [key, value] of Object.entries(this.results)) {
                if(`${value.Texte}` === string) {
                    //alert(`${value.id} => ${value.Texte}`);
                    this.selectedId = `${value.id}`
                }
            }
        }
    },
    watch: {
/*       items: function (val, oldValue) {
        // actually compare them
        if (val.length !== oldValue.length) {
          this.results = val;
          this.isLoading = false;
        } 
      },*/
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    }   
}
</script>

<style scoped>

input {
    width:170px;
    height:30px;  
    margin-right:2px;
    margin-top:2px; 
}
.autocomplete {
    position: relative;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    background:#ccc;    
    border-top: none;
    min-height: 0;
    overflow-y: visible;
    overflow-x: hidden;
    width: 170px;
    z-index:5;
    position:absolute;   
    text-transform:capitalize; 
}

.autocomplete-result {
    list-style: none;   
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active, .autocomplete-result:hover {
    background-color: #007BFF;
    color: white;
}
</style>
