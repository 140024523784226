import 'babel-polyfill'  
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-9989332-1',
  router
})
//import VueCarousel from 'vue-carousel'
import vueHeadful from 'vue-headful';
Vue.component('vue-headful', vueHeadful);
import App from './App.vue'
import router from './router'
import store from './store'
//import Vue2TouchEvents from 'vue2-touch-events'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
import checkDisplayed from './components/CheckDisplayed.vue'
import Pub from './components/Pub.vue'
Vue.component('checkDisplayed', checkDisplayed);
Vue.component('Pub', Pub);
//global variable
/* Vue.prototype.$urlServer = 'dev.install-hc.eu'
Vue.prototype.$protocolServer = 'http' */
Vue.prototype.$urlServer = 'installations-home-cinema.eu/'
Vue.prototype.$protocolServer = 'https'
Vue.prototype.$http = axios


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

