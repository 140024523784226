<template>
  <div>
  </div>  
</template>

<script>
export default { 
  name: 'checkDisplayed',
  components: {    
  },
  props: {
    proprio: {
      type: String,
      default: null
    }   
  },
  data() {         
    return {
      isDisplayed : true      
    }    
  },  
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  mounted() {
    var apiUrl = "/backend/get/form/displayed"      
      fetch(apiUrl,{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({proprio: this.cleanProprio})
        })
        .then(res => res.json())
        .then(res => {          
          if (res.error) {
            this.isDisplayed = false
          } 
          this.pushState()          
        })
  },
  methods: {
    pushState: function(e) {      
      this.$emit("isDisplayed", this.isDisplayed)
    }
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.createAccount {
  margin:0 auto;  
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

a:hover
{
  text-decoration:none;  
}

ul {
  list-style:none;
  color:red;
  font-weight: bold;
  padding-left:5px;
}

#creationAccount {
  text-align:left;
  margin-left:20px;
  padding:10px;
  border-radius:5px;
  min-width:100px;    
  max-width:300px;
}

label {
  width:200px;
}

.blue {
  color: #007bff;
}

</style>